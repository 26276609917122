import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { appActions } from "./actions/routes.action";
import { RootState } from "./app/store";
import {
  RegistrationForm,
  LoginForm,
  ForgotPasswordForm,
} from "./modules/public-forms";
import {
  PurchaseQuantityForm,
  PurchasePaymentForm,
  PurchaseConfirmation,
  PurchaseCompletion,
  PromotionForm,
} from "./modules/purchase-forms";
import { NewPasswordForm } from "./modules/public-forms/forgot-password/NewPassword";
import { Fingerprint } from "./screens/FingerprintExperience/Fingerprint";
import { AllRoutes } from "./AllRoutes";
import { Account } from "./screens/Account/Account";
import { FingerPrintResultPublic } from "./screens/FingerprintExperience/FingerprintResult/FingerPrintResultPublic";

const mapStateToProps = (state: RootState) => {
  const { appRoute } = state.appRoute;
  const { authenticated } = state.authentication;

  return { appRoute, authenticated };
};

const actionCreators = {
  updateAppRoute: appActions.updateAppRoute,
};

const connector = connect(mapStateToProps, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface RouteProps {
  sendRoutesToParent(): void;
}

const Routes = (props: RouteProps & PropsFromRedux) => {
  const location = useLocation();

  useEffect(() => {
    props.sendRoutesToParent();
  }, [location, props]);

  return (
    <Switch>
      <Route path="/fingerprint/:licenseCode">
        <FingerPrintResultPublic />
      </Route>

      {!props.authenticated && (
        <React.Fragment>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/account">
            <Redirect to="/login" />
          </Route>
          <Route path="/register">
            <Redirect to="/registration" />
          </Route>
          <Route path="/registration">
            <RegistrationForm />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordForm />
          </Route>
          <Route path="/auth/reset-password">
            <NewPasswordForm requestKey={location.search} />
          </Route>
          <Route path="/login">
            <LoginForm />
          </Route>
        </React.Fragment>
      )}

      {props.authenticated && (
        <React.Fragment>
          <Route exact path="/">
            <Redirect to="/account" />
          </Route>
          <Route exact path="/login">
            <Redirect to="/account" />
          </Route>
          <Route exact path="/registration">
            <Redirect to="/account" />
          </Route>
          <Route path="/purchase-quantity">
            <PurchaseQuantityForm />
          </Route>
          <Route path="/purchase-payment">
            <PurchasePaymentForm />
          </Route>
          <Route path="/purchase-confirmation">
            <PurchaseConfirmation />
          </Route>
          <Route path="/purchase-completion">
            <PurchaseCompletion />
          </Route>
          <Route path="/enter-promo-code">
            <PromotionForm />
          </Route>
          {/* TODO: BUILD "PURCHASE OVERVIEW" MODULE */}
          <Route exact path="/payments/success">
            <Redirect to="/account" />
          </Route>
          <Route exact path="/payments/cancel">
            <Redirect to="/account" />
          </Route>
          <Route exact path="/account">
            <Account />
          </Route>

          {/* TODO: BUILD "ACCOUNT" MODULE */}

          <Route path="/digital-fingerprint">
            <Fingerprint />
          </Route>

          <Route path="/all-routes">
            <AllRoutes />
          </Route>
        </React.Fragment>
      )}
    </Switch>
  );
};

const connectedRoutes = connector(Routes);
export { connectedRoutes as Routes };
