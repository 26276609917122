import { useDispatch } from "react-redux";
import { useFormData, useValueData } from "../../../../app/hooks";
import { useContext, useEffect, useState } from "react";
import { allActions } from "../../../../app/store";
import { FingerprintContext } from "../../Fingerprint.context";
import { FingerprintForm } from "../../../../modules/fingerprint-steps/fingerprint-form/FingerprintForm";
import { FingerPrintView } from "../../../../features/views/FingerPrintView/FingerPrintView";
import { getValueDrivers } from "../Survey.fn";
import { ArrowIcon } from "../../../../features/icons/Icons";
import { AdditionalQuestions } from "../../../../components/AdditionalQuestions/AdditionalQuestions";

export function AddValues(props: any) {
  const { addToForm } = useFormData();
  const { capitalizeString } = useValueData();
  const dispatch = useDispatch();

  const [currentlyAddedValue, setCurrentlyAddedValue] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  const { selectedValuesByUser, surveyResults, setSelectedValuesByUser } =
    useContext(FingerprintContext);

  const [initialValues, setInitialValues] =
    useState<string[]>(selectedValuesByUser);

  const potentialValues = getValueDrivers(surveyResults);

  const isEmptyFieldError = selectedValuesByUser.some((v) => v === "");

  const isChangedValues =
    JSON.stringify(selectedValuesByUser) !== JSON.stringify(initialValues);

  // Sync up with fetching
  useEffect(() => {
    // We already have values (return to edit screen case)
    if (selectedValuesByUser.length) {
      return;
    }

    setSelectedValuesByUser(potentialValues);
    setInitialValues(potentialValues);
  }, [JSON.stringify(potentialValues)]);

  const onEdit = (value: string, initial: string) => {
    const values = selectedValuesByUser.map((v) => (v === initial ? value : v));
    setSelectedValuesByUser(values);
  };

  const onDelete = (value: string) => {
    const deleted = selectedValuesByUser.find((v) => v === value);

    setSelectedValuesByUser(selectedValuesByUser.filter((v) => v !== deleted));
  };

  const sendToDispatch = (values: Array<string>) => {
    let valuesToDispatch = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== "") {
        values[i] = capitalizeString(values[i]);
        valuesToDispatch.push(values[i]);
      }
    }

    addToForm({ name: "add-values", value: valuesToDispatch.toString() });
  };

  const appendToValues = (newValue: string) => {
    let value = capitalizeString(newValue);
    dispatch(allActions.valueActions.appendValue(value));
  };

  const handleAdd = (event: any) => {
    event.preventDefault();
    if (currentlyAddedValue !== "") {
      setSelectedValuesByUser([...selectedValuesByUser, currentlyAddedValue]);
      sendToDispatch([...selectedValuesByUser, currentlyAddedValue]);
      appendToValues(currentlyAddedValue);
      setCurrentlyAddedValue("");
      setIsAdding(false);
    }
  };

  const includeAdding = () => {
    setIsAdding(true);
  };

  const excludeAdding = () => {
    setIsAdding(false);
  };

  const handleText = (event: any) => {
    setCurrentlyAddedValue(event.target.value);
  };

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Add Values"
        message={
          "Now that you understand the difference between a value and a value-driver, please reflect on each value in the list. Consider whether each one is something you would sacrifice for and if it truly represents you at your best. You can delete values that don't resonate with you or add new ones that better reflect who you are and how you make decisions."
        }
        back={"video-value-drivers"}
        next={"video-values-mapping"}
        nextText="Next"
        navMessage={
          selectedValuesByUser.length < 5
            ? "Please, add at least 5 value-drivers"
            : "You may have up to 10 values."
        }
        disabledNext={selectedValuesByUser.length < 5}
      >
        <div style={{ marginTop: 35, marginBottom: 35 }}>
          <AdditionalQuestions />
        </div>

        {isChangedValues && (
          <div style={{ marginBottom: 35, fontSize: "1.5rem" }}>
            <span
              className="back-button"
              onClick={() => setSelectedValuesByUser(initialValues)}
            >
              Restore
            </span>{" "}
            my initial values.
          </div>
        )}

        <ul className="values">
          {selectedValuesByUser?.map((v, i) => (
            <div key={i} style={{ position: "relative" }}>
              {isEmptyFieldError && v === "" && (
                <div
                  style={{
                    position: "absolute",
                    top: 16,
                    left: -32,
                    color: "darkorange",
                    fontSize: 24,
                    cursor: "default",
                  }}
                  title="Please, use meaningfull and non-empty values"
                >
                  &#9888;
                </div>
              )}

              <input
                id={`${v}-${i}`}
                disabled={isEmptyFieldError && v !== ""}
                style={{
                  width: "100%",
                  height: "6rem",
                  marginBottom: 10,
                  paddingLeft: 20,
                  paddingRight: 125,
                  border: "solid 2px white",
                  background: "transparent",
                  color: "#fff",
                }}
                type="text"
                value={v}
                onChange={({ target: { value } }) => {
                  onEdit(value, v);
                }}
              />

              {surveyResults?.value_drivers_explanation && (
                <div
                  id={`${v}-${i}-explainer`}
                  style={{ display: "none", marginBottom: 20 }}
                >
                  {surveyResults?.value_drivers_explanation[v]}
                </div>
              )}

              <div
                className="edit-panel"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  display: "flex",
                  fontSize: "30px",
                }}
              >
                <div
                  style={{ padding: "14px 8px", cursor: "pointer" }}
                  title="Edit"
                  onClick={() => document.getElementById(`${v}-${i}`)?.focus()}
                >
                  &#9998;
                </div>

                {surveyResults?.value_drivers_explanation && (
                  <div
                    style={{ padding: "14px 8px", cursor: "pointer" }}
                    title={surveyResults?.value_drivers_explanation[v]}
                    onClick={() => {
                      let elem = document.getElementById(`${v}-${i}-explainer`);

                      if (elem) {
                        const display = elem.style.display;

                        elem.style.display =
                          display === "block" ? "none" : "block";
                      }
                    }}
                  >
                    &#8505;
                  </div>
                )}

                <div
                  style={{ padding: "14px 8px", cursor: "pointer" }}
                  title="Delete"
                  onClick={() => onDelete(v)}
                >
                  &#128465;
                </div>
              </div>
            </div>
          ))}
        </ul>

        {isAdding && (
          <div className="adding-a-value">
            <input
              type="text"
              className="adding-value"
              placeholder="type here…"
              onChange={handleText}
              value={currentlyAddedValue}
              autoFocus
            />
            <span className="plus-close" onClick={excludeAdding}>
              ✕
            </span>
          </div>
        )}

        <div className="add-value-button-wrap">
          {/* {!isAdding && liAdded.length + listPV.length <= 9 && ( */}
          {!isAdding && selectedValuesByUser.length <= 9 && (
            <button className="add" onClick={includeAdding}>
              <span className="plus">+</span>
              <span className="btn-text">Add another value</span>
            </button>
          )}

          {isAdding && (
            <button className="add" onClick={handleAdd}>
              <span className="plus">
                <ArrowIcon color="#F7F5F2" />
              </span>
              <span className="btn-text">Add this value</span>
            </button>
          )}

          {!isAdding && selectedValuesByUser.length > 9 && (
            <p style={{ fontSize: "1.5rem" }}>
              You have 10 value-drivers and cannot add any more.
            </p>
          )}
        </div>
      </FingerprintForm>
    </FingerPrintView>
  );
}
