import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import {
  AboutYou,
  ProfessionalLife,
  FirstAFewQuestions,
  VideoValueDrivers,
  ConfirmFingerprint,
  BuildingFingerprint,
} from "../../modules/fingerprint-steps/fingerprint-form";
import { FingerprintMapping } from "../../modules/fingerprint-steps/fingerprint-mapping";
import { useFormData, useValueData } from "../../app/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allActions, RootState } from "../../app/store";
import { SessionService } from "../../services/session.service";
import { useLocation } from "react-router";
import { Survey } from "./Survey/Survey";
import { PotentialValues } from "./Survey/PotentialValues/PotentialValues";
import { FingerprintContext } from "./Fingerprint.context";
import { AddValues } from "./Survey/AddValues/AddValues";
import { VideoValueMapping } from "./Survey/VideoValueMapping/VideoValueMapping";
import { ValuesOrder } from "./Survey/ValuesOrder/ValuesOrder";
import {
  getLocalStorageObject,
  updateLocalStorageObject,
} from "../../utils/localStorage";
import { FingerprintResult } from "./FingerprintResult/FingerprintResult";
import { BeforeWeBegin } from "../BeforeWeBegin/BeforeWeBegin";

const STORAGE_KEY = "Lapin: Survey Data";

export const Fingerprint = () => {
  // Warn user about losing survey progress
  useEffect(() => {
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  let { path, url } = useRouteMatch();
  let location = useLocation();
  const dispatch = useDispatch();
  const { selector, getPotentialValues } = useFormData();
  const { selectorValues, CalculateValueScores } = useValueData();
  const routesSelector = useSelector((state: RootState) => {
    return state.appRoute;
  });
  const [newRoutes, setNewRoutes] = useState<JSX.Element[]>([
    <React.Fragment></React.Fragment>,
  ]);

  const surveyResultsStorage = getLocalStorageObject(
    STORAGE_KEY,
    "surveyResults",
    601
  );

  const [additionalQuestionsRequested, setAdditionalQuestionsRequested] =
    useState(0);
  const [surveyData, setSurveyData] = useState<any[]>([]);
  const [selectedValuesByUser, setSelectedValuesByUser] = useState<any[]>([]);
  const [surveyResults, setSurveyResults] = useState<any>(
    surveyResultsStorage || {}
  );
  const [surveyResultsFinal, setSurveyResultsFinal] = useState<any>({});
  const [valuePhilosophy, setValuePhilosophy] = useState<string>("");

  // TODO: not used?
  useEffect(() => {
    if (selectorValues !== null) {
      setNewRoutes(
        Object.keys(selectorValues).map((value, index) => {
          let back: string = ``;
          let next: string = ``;
          if (index == 0) {
            back = `video-values-mapping`;
          } else {
            back = `mapping-question-${index}`;
          }
          if (index == Object.keys(selectorValues).length - 1) {
            next = `test-your-value-drivers`;
          } else {
            next = `mapping-question-${index + 2}`;
          }

          return (
            <Route path={`${path}/mapping-question-${index + 1}`}>
              <FingerprintMapping
                name={value}
                next={next}
                back={back}
                current={`mapping-question-${index + 1}`}
                message={`Please choose the answer that feels most accurate when reflecting on your value driver of: ${value}`}
                questionType="mapping"
              />
            </Route>
          );
        })
      );
    }
  }, [selectorValues]);

  useEffect(() => {
    let newRoutesArray: Array<string> = [];
    if (newRoutes.length > 0) {
      newRoutes.map((value, index) => {
        if (
          value !== undefined &&
          value.props !== undefined &&
          value.props.path !== undefined
        ) {
          let currentPath: string = value.props.path;
          newRoutesArray.push(currentPath.replace("/digital-fingerprint/", ""));
        }
      });

      dispatch(allActions.appActions.updateDynamicRoutes(newRoutesArray));
    }
  }, [newRoutes]);

  useEffect(() => {
    const valueRoutes = [
      "core-value",
      "personal-choice-values",
      "professional-choice-values",
      "opportunity-conflict-values",
      "disappointment-values",
    ];
    if (
      valueRoutes.includes(routesSelector.appRoute) ||
      routesSelector.appRoute.includes("mapping-question")
    ) {
      CalculateValueScores(getPotentialValues());
    }
  }, [selector]);

  return (
    <FingerprintContext.Provider
      value={{
        surveyData,
        surveyResults,
        surveyResultsFinal,
        selectedValuesByUser,
        additionalQuestionsRequested,
        valuePhilosophy,
        setValuePhilosophy,
        setSurveyData,
        setSurveyResults,
        setSurveyResultsFinal,
        setSelectedValuesByUser,
        setAdditionalQuestionsRequested,
      }}
    >
      <Switch>
        <Route path={`${path}/before-we-begin`}>
          <BeforeWeBegin />
        </Route>

        <Route path={`/digital-fingerprint/your-fingerprint`}>
          <FingerprintResult />
        </Route>

        {SessionService.getInstance().licenseStatus !== "completed" && (
          <Switch>
            <Route path={`${path}/about-you`}>
              <AboutYou />
            </Route>

            <Route path={`${path}/your-professional-life`}>
              <ProfessionalLife />
            </Route>

            <Route path={`${path}/a-few-questions`}>
              <FirstAFewQuestions />
            </Route>

            <Route path={`${path}/earliest-memory`}>
              <Survey />
            </Route>

            {/* <Route path={`${path}/not-very-much`}>
              <NotVeryMuch />
            </Route> */}

            <Route path={`${path}/potential-values`}>
              <PotentialValues name={"potential-values"} />
            </Route>

            <Route path={`${path}/video-value-drivers`}>
              <VideoValueDrivers />
            </Route>

            <Route path={`${path}/add-values`}>
              <AddValues name={"add-potential-values"} />
            </Route>

            {/* <Route path={`${path}/core-value`}>
              <CoreValue />
            </Route> */}

            <Route path={`${path}/video-values-mapping`}>
              <VideoValueMapping />
            </Route>

            {/* {newRoutes} */}

            {/* <Route path={`${path}/test-your-value-drivers`}>
              <ValueDriversTest />
            </Route>

            <Route path={`${path}/personal-choice`}>
              <PersonalChoice />
            </Route>

            <Route path={`${path}/personal-choice-values`}>
              <PersonalChoiceValues />
            </Route>
            
            <Route path={`${path}/professional-choice`}>
              <ProfessionalChoice />
            </Route>

            <Route path={`${path}/professional-choice-values`}>
              <ProfessionalChoiceValues />
            </Route>

            <Route path={`${path}/opportunity-conflict`}>
              <OpportunityConflict />
            </Route>

            <Route path={`${path}/opportunity-conflict-values`}>
              <OpportunityConflictValues />
            </Route>

            <Route path={`${path}/disappointment`}>
              <Disappointment />
            </Route>

            <Route path={`${path}/disappointment-values`}>
              <DisappointmentValues />
            </Route>

            <Route path={`${path}/calculating-values`}>
              <CalculatingValues />
            </Route> */}

            <Route path={`${path}/value-order`}>
              <ValuesOrder />
            </Route>

            <Route path={`${path}/building-fingerprint`}>
              <BuildingFingerprint />
            </Route>

            <Route path={`${path}/confirm-fingerprint`}>
              <ConfirmFingerprint />
            </Route>

            {/* <Route path={`${path}/craft-narrative`}>
              <CraftNarrative />
            </Route> */}

            <Redirect
              to={
                location.pathname.includes("mapping")
                  ? `${path}/test-your-value-drivers`
                  : `/account`
              }
            ></Redirect>
          </Switch>
        )}

        <Redirect to="/account" />
      </Switch>
    </FingerprintContext.Provider>
  );
};
