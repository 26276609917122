import { useState, useEffect } from "react";
import "../../../modules/fingerprint-steps/fingerprint-narrative/CraftNarrative.scss";
import {
  getNarrativeData,
  getUserProfile,
} from "../../../services/user.service";
import { useParams } from "react-router";
import { FingerPrint } from "../../../components/Fingerprint/FingerPrint";
import SessionService from "../../../services/session.service";
import { ISessionUserDto } from "../../../dtos/session-user.dto";

export const FingerPrintResultPublic = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [narrativeData, setNarrativeData] = useState<any>({});
  const [userData, setUserData] = useState<ISessionUserDto | null>(null);

  const params = useParams<{ licenseCode?: string }>();

  const loadValues = async () => {
    try {
      const narrativeData = await getNarrativeData(params.licenseCode || "");

      setNarrativeData(narrativeData);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadUserProfile = async () => {
    if (!narrativeData?.userId) {
      return;
    }

    try {
      const userData = await getUserProfile(parseInt(narrativeData?.userId));

      setUserData(userData);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadValues();
  }, []);

  const { licenseCode } = SessionService.getInstance();

  useEffect(() => {
    loadUserProfile();
  }, [narrativeData?.userId]);

  return (
    <FingerPrint
      isLoading={isLoading}
      userName={`${userData?.firstName || ""} ${userData?.lastName || ""}`}
      licenseCode={licenseCode || ""}
      valueDrivers={narrativeData?.rankedValues?.split(",")}
      valuePhilosophy={narrativeData?.valuesPhilosophy}
      archetype={narrativeData?.archetype}
      archetypeExplanation={narrativeData?.archetypeExplanation}
      triggers={narrativeData?.triggers?.split(",")}
      triggersExplanation={narrativeData?.triggersExplanation
        ?.split(";;")
        ?.reduce((acc: any, curr: any) => {
          const explanation = curr?.split(":");

          return {
            ...acc,
            [explanation[0]]: explanation[1],
          };
        }, {})}
    />
  );
};
