import { FC, useContext, useEffect } from "react";
import { FingerprintContext } from "../Fingerprint.context";
import { useValueOrder, useValuePhilosophy } from "../Survey/Survey.hooks";
import {
  changeLicenseStatus,
  saveRankedValuesArray,
} from "../../../services/user.service";
import { getValueDrivers } from "../Survey/Survey.fn";
import { FingerPrint } from "../../../components/Fingerprint/FingerPrint";
import SessionService from "../../../services/session.service";

export const FingerprintResult: FC = () => {
  const {
    surveyData,
    surveyResults,
    surveyResultsFinal,
    valuePhilosophy,
    selectedValuesByUser,
  } = useContext(FingerprintContext);

  const { getValueOrder, isLoading: isLoadingValueOrder } = useValueOrder(
    601,
    selectedValuesByUser,
    surveyData,
    true
  );

  const { getValuePhilosophy, isLoading } = useValuePhilosophy(601);

  useEffect(() => {
    getValueOrder().then((res) => {
      getValuePhilosophy(res?.value_drivers, res?.value_drivers_explanation);
    });
  }, []);

  useEffect(() => {
    if (valuePhilosophy) {
      changeLicenseStatus("completed");
    }
  }, [valuePhilosophy]);

  const { licenseCode, user } = SessionService.getInstance();

  useEffect(() => {
    // valuePhilosophy is loaded last, so it can be an indication that everything is loaded
    // and we can save the survey data to db
    if (valuePhilosophy && Object.keys(surveyResults).length) {
      saveRankedValuesArray(
        selectedValuesByUser,
        valuePhilosophy,
        surveyResults?.archetype,
        surveyResults?.archetype_explanation,
        surveyResults?.triggers,
        Object.entries(surveyResults?.triggers_explanation)?.map(
          (e) => `${e[0]}: ${e[1]}`
        ),
        parseInt(user?.id || "0")
      );
    }
  }, [valuePhilosophy]);

  const valueDrivers = getValueDrivers(surveyResultsFinal);

  return (
    <FingerPrint
      isLoading={isLoading || isLoadingValueOrder}
      userName={user?.fullName || ""}
      licenseCode={licenseCode || ""}
      valueDrivers={valueDrivers}
      valuePhilosophy={valuePhilosophy}
      archetype={surveyResults?.archetype}
      archetypeExplanation={surveyResults?.archetype_explanation}
      triggers={surveyResults?.triggers}
      triggersExplanation={surveyResults?.triggers_explanation}
    />
  );
};
