import { connect, ConnectedProps } from "react-redux";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { FormikProps, Form, withFormik } from "formik";
import * as Yup from "yup";
import { MessageBox } from "../../helpers";
import { userActions } from "../../../actions/login.action";
import { RootState } from "../../../app/store";
import { ArrowIcon } from "../../../features/icons/Icons";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Link } from "react-router-dom";
import SessionService from "../../../services/session.service";
import { useEffect } from "react";
import { useHistory } from "react-router";

const mapState = (state: RootState) => {
  const { _processing, _errors, user } = state.authentication;
  return { _processing, _errors, user };
};

const actionCreators = {
  login: userActions.login,
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface LoginFormValues {
  email: string;
  password: string;
}

interface OtherProps extends PropsFromRedux {
  message?: string;
}

function InnerLoginForm(props: OtherProps & FormikProps<LoginFormValues>) {
  const history = useHistory();

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    _errors,
    _processing,
  } = props;

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Login"
      background="lightgrey"
    >
      <Form noValidate id="login" className="login">
        <h3>{props.message}</h3>

        {_errors && <MessageBox variant="danger" messages={_errors} />}

        <FormGroup>
          <FormLabel>Email :</FormLabel>
          <FormControl
            type="text"
            name="email"
            placeholder="Email"
            className={touched.email && errors.email ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {touched.email && errors.email && (
            <div className="error-message">{errors.email}</div>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Password :</FormLabel>
          <FormControl
            type="password"
            name="password"
            placeholder="Password"
            className={touched.password && errors.password ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          {touched.password && errors.password && (
            <div className="error-message">{errors.password}</div>
          )}
        </FormGroup>

        {_processing && <div>Loading</div>}

        <div className="bottom-options">
          <Link to="/forgot-password">
            <span id="forgot-password">Forgot Password</span>
          </Link>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Login
            <ArrowIcon />
          </Button>
        </div>
      </Form>
      <span className="bottom-message">
        <p>
          Not registered yet? <Link to="/registration">Sign up</Link> to get
          started.
        </p>
      </span>
    </FingerPrintView>
  );
}

interface LoginFormProps extends PropsFromRedux {
  email?: string;
  message?: string;
}

const LoginForm = withFormik<LoginFormProps, LoginFormValues>({
  // Transform outer props into form values
  mapPropsToValues: (props) => {
    return {
      email: props.email || "", //TODO: remove this and set to blank
      password: "", //TODO: remove this and set to blank
    };
  },

  // Add a custom validation function (this can be async too!)
  validationSchema: () => {
    const schema = Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .email("Not a valid email address"),
      password: Yup.string().required("Password is a required field"),
    });

    return schema;
  },

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    let user = SessionService.getInstance().user;
    if (user) {
      setErrors({ email: "A user is logged in, please log out." });
    } else {
      const { login } = props;
      setSubmitting(false);
      login(values.email, values.password)
        .then((resp) => {
          if (SessionService.getInstance().token) {
            window.location.replace("/account");
          }
        })
        .catch((e: any) => {
          console.log(e);
          setErrors({ email: e.messages });
        });
    }
  },
})(InnerLoginForm);

const connectedLoginPage = connector(LoginForm);
export { connectedLoginPage as LoginForm };
