import "./ValuesOrder.scss";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useContext, useEffect } from "react";
import { allActions } from "../../../../app/store";
import { FingerPrintView } from "../../../../features/views/FingerPrintView/FingerPrintView";
import { AudioGuide } from "../../../../modules/fingerprint-steps/fingerprint-narrative";
import { SingleOrderableValue } from "./SingleOrderableValue/SingleOrderableValue";
import { ArrowIcon } from "../../../../features/icons/Icons";
import { FingerprintContext } from "../../Fingerprint.context";
import { useValueOrder } from "../Survey.hooks";
import { AdditionalQuestions } from "../../../../components/AdditionalQuestions/AdditionalQuestions";

export function ValuesOrder() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { surveyData, selectedValuesByUser, setSelectedValuesByUser } =
    useContext(FingerprintContext);

  const { getValueOrder, isLoading } = useValueOrder(
    601,
    selectedValuesByUser,
    surveyData,
    false
  );

  useEffect(() => {
    getValueOrder();
  }, []);

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "calculating-values",
        "value-order",
        "building-fingerprint"
      )
    );
  }, []);

  const moveValue = (direction: string, index: number) => {
    let currentValues: Array<string> = [...selectedValuesByUser];
    let placeholder: string = "";
    if (direction === "upward") {
      placeholder = currentValues[index - 1];
      currentValues[index - 1] = currentValues[index];
      currentValues[index] = placeholder;
    } else if (direction === "downward") {
      placeholder = currentValues[index + 1];
      currentValues[index + 1] = currentValues[index];
      currentValues[index] = placeholder;
    }
    setSelectedValuesByUser(currentValues);
  };

  const hasValues = selectedValuesByUser.length > 0;

  return (
    <>
      <FingerPrintView background="darkblue" className="no-shapes">
        {isLoading && (
          <p style={{ fontSize: "3rem" }}>
            We are now systematizing your Value Drivers...
          </p>
        )}

        {!isLoading && <AudioGuide />}

        {!isLoading && (
          <div className="values-order">
            <div className="valus-order-intro">
              <h1>Your Leadership Fingerprint™</h1>
              <p>
                We've analyzed your answers and developed a draft of your
                personalized Leadership Fingerprint™. Please take a moment to
                review each value-driver below and ensure they are in the proper
                order.
              </p>
            </div>

            <div className="values-order-list">
              <ul>
                {selectedValuesByUser.map((v, i) => {
                  return (
                    <SingleOrderableValue
                      key={i}
                      valueName={v}
                      number={i}
                      previous={selectedValuesByUser[i - 1]}
                      isLast={i === selectedValuesByUser.length - 1}
                      move={moveValue}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        )}

        {!hasValues && (
          <>
            <div>No active survey session is found.</div>
            <div>
              <button
                className="btn btn-primary white"
                onClick={() => {
                  history.push("/digital-fingerprint/earliest-memory");
                }}
              >
                Start new survey
              </button>
            </div>
          </>
        )}

        {!isLoading && hasValues && (
          <section className="value-order-accuracy">
            <h3>Do your values feel accurate?</h3>

            <div style={{ marginTop: 30, fontSize: "1.5rem" }}>
              Please, let me rethink and{" "}
              <span
                className="back-button"
                onClick={() => {
                  history.push("/digital-fingerprint/add-values");
                }}
              >
                edit my values
              </span>
            </div>

            <div className="button-row">
              <button
                onClick={() => {
                  history.push("/digital-fingerprint/confirm-fingerprint");

                  // saveRankedValuesArray(selectedValuesByUser)
                  //   .then(() => {
                  //     history.push("/digital-fingerprint/confirm-fingerprint");
                  //   })
                  //   .catch((error) => {
                  //     updateHttpError({
                  //       messages: (error as IError).messages,
                  //       statusCode: (error as IError).statusCode,
                  //     });
                  //   });
                }}
              >
                Yes, create my personal fingerprint <ArrowIcon />
              </button>

              <AdditionalQuestions />

              {/* <button
                onClick={() => {
                  setSurveyData([]);
                  setAdditionalQuestionsRequested(0);

                  history.push("/digital-fingerprint/earliest-memory");
                }}
              >
                Not quite, let’s retake my value assessment{" "}
                <ArrowIcon color="#82AAF9" />
              </button> */}
            </div>

            <span className="final-message">
              By clicking “Yes, Create My Personal Fingerprint” I am confirming
              my values and completing my Lapin Leadership Assessment.
            </span>
          </section>
        )}
      </FingerPrintView>
    </>
  );
}
