import { connect, ConnectedProps } from "react-redux";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { FormikProps, Form, withFormik } from "formik";
import * as Yup from "yup";
import { MessageBox } from "../../helpers";
import { userActions } from "../../../actions/login.action";
import { RootState } from "../../../app/store";
import { ArrowIcon } from "../../../features/icons/Icons";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Link } from "react-router-dom";

const mapState = (state: RootState) => {
  const { _processing, _errors } = state.authentication;
  return { _processing, _errors };
};

const actionCreators = {
  login: userActions.login,
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PromotionFormValues {
  promoCode: number;
}

interface OtherProps extends PropsFromRedux {
  message?: string;
}

function InnerPromotionForm(
  props: OtherProps & FormikProps<PromotionFormValues>
) {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    _errors,
    _processing,
  } = props;

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Have a Promo Code?"
      background="lightgrey"
    >
      <Form noValidate id="purchase_promoCode" className="purchase-promoCode">
        <h3>{props.message}</h3>

        {_errors && <MessageBox variant="danger" messages={_errors} />}

        <FormGroup>
          <FormLabel>Assessment Purchase promoCode</FormLabel>
          {/* <span className="field-description">
            How many assessments would you like for this license?
          </span> */}
          <FormControl
            type="text"
            name="Promo Code"
            placeholder="Enter Promo Code…"
            className="promo-code"
            //   onChange={handleChange}
            //   onBlur={handleBlur}
            value={values.promoCode}
          />
          {touched.promoCode && errors.promoCode && (
            <div className="error-message">{errors.promoCode}</div>
          )}
        </FormGroup>

        {_processing && <div>Loading</div>}
        <Row className="bottom-options">
          <Link to="/account">Back</Link>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Next
            <ArrowIcon />
          </Button>
        </Row>
      </Form>
    </FingerPrintView>
  );
}

interface PromotionFormProps extends PropsFromRedux {
  promoCode?: string;
  message?: string;
}

const PromotionForm = withFormik<PromotionFormProps, PromotionFormValues>({
  // Transform outer props into form values
  // mapPropsToValues: (props) => {
  //   return {
  //     promoCode: props.promoCode || "admin@lapin.com", //TODO: remove this and set to blank
  //     firstName: props.firstName, //TODO: remove this and set to blank
  //     lastName: props.lastName,
  //   };
  // },

  // Add a custom validation function (this can be async too!)
  validationSchema: () => {
    const schema = Yup.object({
      promoCode: Yup.string().required("promoCode is a required field"),
    });

    return schema;
  },

  handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerPromotionForm);

const connectedPromotionPage = connector(PromotionForm);
export { connectedPromotionPage as PromotionForm };
