import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { FormikProps, Form, withFormik } from "formik";
import { connect, ConnectedProps } from "react-redux";
import * as Yup from "yup";
import { MessageBox } from "../../helpers";
import { ArrowIcon } from "../../../features/icons/Icons";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Link } from "react-router-dom";
import { register } from "../../../services/user.service";
import { userActions } from "../../../actions/login.action";
import store, { RootState } from "../../../app/store";
import SessionService from "../../../services/session.service";

//Conntect to store to dispatch login
const mapState = (state: RootState) => {
  const { _processing, _errors } = state.authentication;
  return { _processing, _errors };
};

const actionCreators = {
  login: userActions.login,
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface OtherProps extends PropsFromRedux {
  message?: string;
}

interface RegistrationFormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

function InnerRegistrationForm(
  props: OtherProps & FormikProps<RegistrationFormValues>
) {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    status,
  } = props;

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Registration"
      background="lightgrey"
    >
      <Form noValidate id="register" className="register">
        {status._errors && (
          <MessageBox variant="danger" messages={status._errors} />
        )}
        <FormGroup>
          <FormLabel>Email</FormLabel>
          <FormControl
            type="text"
            name="email"
            placeholder="Email…"
            className={touched.email && errors.email ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
        </FormGroup>
        <FormGroup>
          <FormLabel>First Name</FormLabel>
          <FormControl
            type="text"
            name="firstName"
            placeholder="First Name…"
            className={touched.firstName && errors.firstName ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
          />
          {errors.firstName && (
            <div className="error-message">{errors.firstName}</div>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>Last Name</FormLabel>
          <FormControl
            type="text"
            name="lastName"
            placeholder="Last Name…"
            className={touched.lastName && errors.lastName ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
          />
          {errors.lastName && (
            <div className="error-message">{errors.lastName}</div>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>Password :</FormLabel>
          <FormControl
            type="password"
            name="password"
            placeholder="Password"
            className={touched.password && errors.password ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          {errors.password && (
            <div className="error-message">{errors.password}</div>
          )}
        </FormGroup>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Register
          <ArrowIcon />
        </Button>
        <span className="form-message">
          <p>
            <i>
              {/* Note: if you do not have an Assessment Key, you will be required
              to purchase one as part of the registration process at $0.00 / per
              assessment. Discounts are available for purchasing multiple
              licenses for the leaders within your organization. */}
              Note: we need your basic info to conduct a Leadership Fingerprint™
              Assessment.
            </i>
          </p>
        </span>
      </Form>
      <span className="bottom-message">
        <p>
          Already registered? <Link to="/login">Login</Link> to get started.
        </p>
      </span>
    </FingerPrintView>
  );
}

interface RegistrationFormProps extends PropsFromRedux {
  email?: string;
  message?: string;
}

const RegistrationForm = withFormik<
  RegistrationFormProps,
  RegistrationFormValues
>({
  mapPropsToStatus: (props) => {
    return {
      _errors: null,
      _success: false,
    };
  },

  mapPropsToValues: (props) => {
    return {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
    };
  },
  // Add a custom validation function (this can be async too!)
  validationSchema: () => {
    const schema = Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .email("Not a valid email address"),
      firstName: Yup.string().required("First name is a required field"),
      lastName: Yup.string().required("Last name is a required field"),
      password: Yup.string()
        .min(7, "Password is too short.")
        .required("Password is a required field"),
    });

    return schema;
  },

  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    let newUser;
    try {
      newUser = await register(
        values.firstName,
        values.lastName,
        values.email,
        values.password
      );

      setStatus({ _success: true });
      const { login } = props;
      setSubmitting(false);
      login(values.email, values.password).then((resp) => {
        if (SessionService.getInstance().token) {
          window.location.replace("/account");
        }
      });
      // window.location.replace("/login");
    } catch (e: any) {
      setStatus({ _errors: [e.messages] });
    }
  },
})(InnerRegistrationForm);

const connectedRegistrationPage = connector(RegistrationForm);
export { connectedRegistrationPage as RegistrationForm };
