import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { FormikProps, Form, withFormik } from "formik";
import * as Yup from "yup";
import { MessageBox } from "../../helpers";
import { userActions } from "../../../actions/login.action";
import { RootState } from "../../../app/store";
import { ArrowIcon } from "../../../features/icons/Icons";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Link } from "react-router-dom";
import { requestRecoverPassword } from "../../../services/user.service";

const mapState = (state: RootState) => {
  const { _processing, _errors } = state.authentication;
  return { _processing, _errors };
};

const actionCreators = {
  login: userActions.login, //needs new action for 'forgot password'
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ForgotPasswordFormValues {
  email: string;
  // firstName: string;
  // lastName: string;
}

interface OtherProps extends PropsFromRedux {
  message?: string;
}

function InnerForgotPasswordForm(
  props: OtherProps & FormikProps<ForgotPasswordFormValues>
) {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    _errors,
    _processing,
    status,
  } = props;

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Forgot Password"
      background="lightgrey"
    >
      <Form noValidate id="forgot_password" className="forgot-password">
        <h3>{props.message}</h3>

        {/* {_errors && <MessageBox variant="danger" messages={_errors} />} */}
        {status._errors && (
          <MessageBox variant="danger" messages={status._errors} />
        )}
        {status._success && <MessageBox messages={status._success} />}
        <FormGroup>
          <FormLabel>Email</FormLabel>
          <span className="field-description">
            Enter your email address and we will send you a link to reset your
            password.
          </span>
          <FormControl
            type="text"
            name="email"
            placeholder="Email…"
            className={touched.email && errors.email ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {touched.email && errors.email && (
            <div className="error-message">{errors.email}</div>
          )}
        </FormGroup>

        {_processing && <div>Loading</div>}

        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Submit
          <ArrowIcon />
        </Button>
      </Form>

      <span className="bottom-message">
        <p>
          Already registered? <Link to="/login">Login</Link> to get started.
        </p>
      </span>
    </FingerPrintView>
  );
}

interface ForgotPasswordFormProps extends PropsFromRedux {
  email?: string;
  //message?: string;
}

const ForgotPasswordForm = withFormik<
  ForgotPasswordFormProps,
  ForgotPasswordFormValues
>({
  //Transform outer props into form values
  mapPropsToValues: (props) => {
    return {
      email: props.email || "", //TODO: remove this and set to blank
      // firstName: props.firstName, //TODO: remove this and set to blank
      // lastName: props.lastName,
    };
  },
  mapPropsToStatus: (props) => {
    return {
      _errors: null,
      _success: false,
    };
  },
  validationSchema: () => {
    const schema = Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .email("Not a valid email address"),
    });

    return schema;
  },

  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    requestRecoverPassword(values.email)
      .then((resp) => {
        setStatus({
          _success: [
            "Email to recover password was sent successfully - Redirecting to Log In page.",
          ],
        });
        setTimeout(() => {
          window.location.replace("/login");
        }, 4000);
      })
      .catch((e: any) => {
        setStatus({ _errors: [e.messages] });
      })
      .finally(() => {
        setSubmitting(false);
      });
  },
})(InnerForgotPasswordForm);

const connectedLoginPage = connector(ForgotPasswordForm);
export { connectedLoginPage as ForgotPasswordForm };
