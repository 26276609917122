import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allActions } from "../../../../app/store";
import { FingerPrintView } from "../../../../features/views/FingerPrintView/FingerPrintView";
import { FingerprintForm } from "../../../../modules/fingerprint-steps/fingerprint-form/FingerprintForm";
import { VIDEO_MAPPING } from "../../../../assets/media";
import { PlayButton } from "../../../../features/icons/Icons";

export function VideoValueMapping() {
  const dispatch = useDispatch();
  const [autoPlaying, setAutoPlaying] = useState(false);

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "core-value",
        "video-value-mapping",
        "mapping-question-1"
      )
    );
  }, []);

  function toggleAutoPlay() {
    setAutoPlaying(true);
  }

  return (
    <FingerPrintView background="navy">
      <FingerprintForm
        name="Value Drivers Mapping"
        message="Thank you. Let's now explore how your value-drivers fit together."
        // explainer="More questions will be asked in these final sections in order to understand how your value-drivers fit together."
        back="add-values"
        next="value-order"
        nextText="Go"
        navMessage="Please watch the video before advancing."
      >
        <div className="video-wrapper">
          {autoPlaying && (
            <video autoPlay controls>
              <source src={VIDEO_MAPPING.default} type="video/mp4" />
            </video>
          )}

          {!autoPlaying && (
            <video>
              <source src={VIDEO_MAPPING.default} type="video/mp4" />
            </video>
          )}

          <div
            className={`play-button-wrapper ${autoPlaying ? "hide" : ""}`}
            onClick={toggleAutoPlay}
          >
            <PlayButton />

            <span>Play Video</span>
          </div>
        </div>
      </FingerprintForm>
    </FingerPrintView>
  );
}
