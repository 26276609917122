import React, { Fragment } from "react";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Button, Row, Col } from "react-bootstrap";
import { ArrowIcon } from "../../../features/icons/Icons";
import { Link } from "react-router-dom";

export function PurchaseCompletion(props: any) {
  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Purchase Assessment Keys"
      background="lightgrey"
      purchaseStep="completion"
      className="purchase-completion"
    >
      <br />
      <br />
      [[INSERT ORDER DETAILS]]
    </FingerPrintView>
  );
}
