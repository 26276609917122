import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useErrorHandler, useFormData } from "../../../../app/hooks";
import { allActions } from "../../../../app/store";
import { convertToString } from "./PotentialValues.fn";
import { FingerPrintView } from "../../../../features/views/FingerPrintView/FingerPrintView";
import { MessageBox } from "../../../../modules/helpers";
import { FingerprintForm } from "../../../../modules/fingerprint-steps/fingerprint-form/FingerprintForm";
import { statusToVariant } from "../../../../services/user.service";
import { FingerprintContext } from "../../Fingerprint.context";
import { useSurveyResults } from "../Survey.hooks";
import { getValueDrivers } from "../Survey.fn";
import { AdditionalQuestions } from "../../../../components/AdditionalQuestions/AdditionalQuestions";

export function PotentialValues(props: any) {
  const dispatch = useDispatch();

  const { surveyResults } = useContext(FingerprintContext);

  const { getSurveyResults, isLoading } = useSurveyResults(601);

  const { addToForm } = useFormData();

  const [values, setValues] = useState<Array<string>>([""]);

  const { httpError } = useErrorHandler();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "video-value-drivers",
        "potential-values",
        "add-values"
      )
    );
  }, []);

  const handleChange = (e: any) => {
    let currentValues = [...values];

    if (currentValues.includes(e.target.value)) {
      currentValues.splice(currentValues.indexOf(e.target.value), 1);
    } else {
      currentValues = [...values, e.target.value];
    }

    setValues(currentValues.filter((element) => element != ""));

    addToForm(
      {
        name: e.target.name,
        value: convertToString(
          currentValues.filter((element) => element != "")
        ),
      },
      e.target.type
    );
  };

  useEffect(() => {
    getSurveyResults();
  }, []);

  const valueDrivers = getValueDrivers(surveyResults);

  return (
    <FingerPrintView background="darkblue">
      {httpError.statusCode > 399 && (
        <MessageBox
          messages={httpError.messages}
          variant={statusToVariant(httpError.statusCode)}
        />
      )}

      <FingerprintForm
        name="Potential Values"
        message={
          isLoading
            ? "We are now calculating Values for your review...."
            : "Here are a few potential values we pulled from your stories to get us started. Please take a moment to review the following list of values. As you watch the upcoming video, consider whether these values accurately reflect your most authentic self. Following the video, you will have the opportunity to adjust this list as needed."
        }
        back={isLoading ? "" : "earliest-memory"}
        next={isLoading ? "" : "video-value-drivers"}
        nextText="Next"
        navMessage="You will be able to edit these values and add more on the next step."
      >
        {!isLoading && (
          <div style={{ marginTop: 35, marginBottom: 35 }}>
            <AdditionalQuestions />
          </div>
        )}

        {!isLoading && (
          <fieldset>
            <ul className="values">
              {valueDrivers.map((v: any, i: number) => {
                return (
                  <Fragment key={v}>
                    <li key={i} className="value">
                      <input
                        name={props.name}
                        type="checkbox"
                        value={v}
                        onChange={handleChange}
                        checked={values.includes(v)}
                      />
                      <label>{v}</label>
                    </li>
                    <li key={v} className="explanation">
                      {surveyResults?.value_drivers_explanation[v]}
                    </li>
                  </Fragment>
                );
              })}
            </ul>
          </fieldset>
        )}
      </FingerprintForm>
    </FingerPrintView>
  );
}
