import API from "../API";
import SessionService from "./session.service";
import { ISessionUserDto } from "../dtos/session-user.dto";
import { AxiosRequestConfig } from "axios";
import { IOrderDto } from "../dtos/order.dto";
import { PaginatedResultDto } from "../dtos/paginated-result.dto";
import { formState } from "../actions/form.action";
import { valueState } from "../actions/values.action";
import { INarrative } from "../dtos/narrative.dto";
import { aboutYouDataDto } from "../dtos/aboutYouData.dto";
import { professionalLifeDataDto } from "../dtos/professionalLifeDto.dto";
import { ILicenseDto } from "../dtos/license.dto";
export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",
};

export interface serverResponse {
  statusCode: number;
  messages: string[];
}

export interface IError {
  statusCode: number;
  message: Array<string>;
  messages: Array<string>;
  path: string;
  errors: Array<string>;
  status: number;
  timestamp: Date;
}

export interface IUserLoginReturnDto {
  access_token: string;
}

export const statusToVariant = (statusCode: number): string => {
  let variant: string = "";
  if (statusCode > 399) {
    variant = "danger";
  }
  if (statusCode < 300 && statusCode > 200) {
    variant = "success";
  }
  return variant;
};

export const login = async (
  email: string,
  password: string
): Promise<ISessionUserDto> => {
  const result = await API.post<IUserLoginReturnDto>("/auth/login", {
    email: email,
    password: password,
  });

  const accessToken = result.data.access_token;

  const user = await getProfile(accessToken);
  SessionService.getInstance().setToken(accessToken);
  SessionService.getInstance().setUser(user);

  return user;
};

export const getProfile = async (
  accessToken?: string
): Promise<ISessionUserDto> => {
  const config: AxiosRequestConfig = {};

  if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const result = await API.get<ISessionUserDto>("/users/me", config);
  return result.data;
};

export const getUserProfile = async (id: number): Promise<ISessionUserDto> => {
  const result = await API.get<ISessionUserDto>(`/users/${id}`);
  return result.data;
};

export const logout = (): boolean => {
  SessionService.getInstance().clear();
  window.location.replace("/login");
  return true;
};

export const register = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string
) => {
  const result = await API.post("/users/register", {
    firstName,
    lastName,
    email,
    password,
  });

  return result.data;
};

export const getOrders = async () => {
  const result = await API.get<PaginatedResultDto<IOrderDto>>(
    `/users/me/orders`
  );
  return result.data;
};

export const addNewOrder = async () => {
  //for debugging purpose (delete soon)
  const result = await API.post<IOrderDto>(`users/me/orders/new`);
  return result.data;
};

export const getLicenses = async () => {
  const result = await API.get(`users/me/licenses`);
  return result.data;
};

export const requestRecoverPassword = async (email: string) => {
  const result = await API.post<Promise<Boolean>>(
    "/users/request-recover-password",
    { email, url: process.env.REACT_APP_EXPERIENCE_APP_URL }
  );

  return result.data;
};

export const recoverPassword = async (
  email: string,
  password: string,
  key: string
) => {
  const result = await API.post<Promise<Boolean>>("/users/recover-password", {
    email,
    newPassword: password,
    key,
  });
  return result.data;
};
export const saveUserFingerprintData = async (
  data: string,
  licenseCode: string,
  lastPageAccessed: string,
  visitedRoutes: Array<string>
) => {
  const config: AxiosRequestConfig = {};
  const accessToken = SessionService.getInstance().token;
  const user = SessionService.getInstance().user;
  if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const result = await API.post<Promise<Boolean>>(
    "fingerprintData/save-fingerprint-data",
    {
      data,
      userId: user?.id,
      licenseCode,
      lastPageAccessed,
      visitedRoutes: visitedRoutes.toString(),
    }
  );

  return result.data;
};

export const getFingerprintData = async (licenseCode: string | undefined) => {
  const config: AxiosRequestConfig = {};
  const accessToken = SessionService.getInstance().token;
  const user = SessionService.getInstance().user;
  const result = await API.get(
    `fingerprintData/data/${user?.id}/${licenseCode}`
  );
  return result.data;
};

export const getValuesLookUpTable = async () => {
  const result = await API.get(`values/phrases`);
  return result.data;
};

export const saveRankedValuesArray = async (
  rankedValues: string[],
  valuesPhilosophy: string,
  archetype: string,
  archetypeExplanation: string,
  triggers: string[],
  triggersExplanation: string[],
  userId: number
) => {
  await API.post("narrative/save-values", {
    licenseCode: SessionService.getInstance().licenseCode,
    values: rankedValues.toString(),
    valuesPhilosophy,
    archetype,
    archetypeExplanation,
    triggers: triggers.toString(),
    // So it can be split by ";;"
    triggersExplanation: triggersExplanation.join(";;"),
    userId,
  });
};

// TODO: check not used
export const testSaveValues = async (rankedValues: Array<string>) => {
  const result = await API.post("narrative/save-values-test", {
    values: rankedValues.toString(),
  });
};

export const getNarrativeData = async (licenseCode: string) => {
  const result = await API.get(`narrative/get-values/${licenseCode}`);
  return result.data;
};

// TODO: check not used
export const saveNarrative = async (narrative: string) => {
  return await API.post("narrative/save-narrative", {
    narrative: narrative,
    licenseCode: SessionService.getInstance().licenseCode,
  });
};

/**
 * Save survey data to db
 *
 * Continue where you left functionality
 *
 * https://trello.com/c/oWoc1EDS/133-provide-continue-where-you-left-functionality-20h
 * @param surveyData Lapin 2.0 Survey Data object
 * @returns
 */
export const saveSureyData = async (surveyData: any[]) => {
  return await API.post("narrative/save-survey-data", {
    licenseCode: SessionService.getInstance().licenseCode,
    surveyData,
  });
};

export const getNarrative = async () => {
  const result = await API.get(
    `narrative/get-narrative/${SessionService.getInstance().licenseCode}`
  );
  return result.data;
};

export const changeLicenseStatus = async (newStatus: string) => {
  const result = await API.post("/users/me/license-status", {
    licenseCode: SessionService.getInstance().licenseCode,
    newStatus: newStatus,
  });
};

export const getNarrativeAndUser = async (licenseCode: string) => {
  const result = await API.get<Promise<INarrative>>(
    `narrative/public-narrative/${licenseCode}`
  );
  return result.data;
};

export const saveAboutYouData = async (
  aboutYouData: aboutYouDataDto
): Promise<boolean> => {
  return await API.post("fingerprintData/save-about-you", {
    aboutYouData,
  });
};

export const saveProfessionalLifeDAta = async (
  professionalLifeData: professionalLifeDataDto
): Promise<boolean> => {
  return await API.post("fingerprintData/save-professional-life", {
    professionalLifeData,
  });
};

export const addLicenseToUser = async (
  licenseCode: string
): Promise<ILicenseDto> => {
  const result = await API.post("users/me/add-license", { licenseCode });

  return result.data;
};

export const validateLicense = async (code: string): Promise<ILicenseDto> => {
  const result = await API.post("coupons/validate", { code });

  return result.data;
};
