import { FC, useState } from "react";
import { FingerPrintView } from "../../features/views/FingerPrintView/FingerPrintView";
import { CustomFingerprint, LinkIcon } from "../../features/icons/Icons";
import { Button, Col, Row } from "react-bootstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFExport } from "../../modules/fingerprint-steps/fingerprint-narrative/PDFExport";
import fingerprintImg from "../../assets/media/fingerprint.svg";

type Props = {
  userName: string; // first name + last name
  licenseCode: string;
  valueDrivers: string[];
  archetype: string;
  archetypeExplanation: string;
  valuePhilosophy: string;
  triggers: string[];
  triggersExplanation: string[];
  isLoading: boolean;
};

export const FingerPrint: FC<Props> = ({
  userName,
  licenseCode,
  valueDrivers,
  archetype,
  archetypeExplanation,
  valuePhilosophy,
  triggers,
  triggersExplanation,
  isLoading,
}) => {
  const [showShareLink, setShowShareLink] = useState<boolean>(false);

  return (
    <FingerPrintView background="navy" className="no-shapes stretch">
      <div className="final-fingerprint color-navy">
        <section className="fingerprint">
          <div
            className="title-card"
            style={{ position: "relative", top: -35 }}
          >
            <h1>Your Leadership Fingerprint™</h1>
          </div>

          <div className="fingerprint-wrapper">
            <span className="name">{userName}</span>

            <div className="fingerprint-inner-wrapper">
              <div className="fingerprint-line" />

              <ul className="list-of-values">
                {isLoading
                  ? "Loading..."
                  : valueDrivers?.map((v) => {
                      return <li key={v}>{v}</li>;
                    })}
              </ul>
            </div>

            <div className="fingerprint-svg">
              <div className="fingerprint-svg-bg" />

              <CustomFingerprint />

              <img src={fingerprintImg} className="fingerprint-bg" alt="" />
            </div>
          </div>
        </section>
      </div>

      <section className="final-narrative">
        <section
          className="fingerprint"
          style={{ marginTop: "15rem", maxWidth: "70%" }}
        >
          <div className="row">
            <div className="title-card">
              <h1>Values Philosophy</h1>
            </div>

            {isLoading ? "Loading..." : valuePhilosophy}
          </div>

          <div className="row">
            <div className="title-card">
              <h1>Your Defensive Archetype</h1>
            </div>
            Your Leadership Fingerprint™ represents who you are at your best. We
            all experience moments when we don't show up as our ideal selves.
            This is where understanding your Defensive Archetype becomes
            essential. Your Defensive Archetype describes how you behave when
            triggered into a defensive state. By identifying these behaviors and
            recognizing what triggers them, you can learn to manage and master
            your reactions. This awareness helps you align more closely with
            your values system and more consistently present your best self.
          </div>

          <div className="row">
            <h3>Archetype:</h3>

            <p>{archetype}</p>
          </div>

          <div className="row">
            <h3>Archetype Explanation:</h3>

            <p>{archetypeExplanation}</p>
          </div>

          <div className="row">
            <h3>Triggers:</h3>

            <p>{triggers?.join(", ")}</p>
          </div>

          <div className="row">
            <h3>Triggers Explanation:</h3>

            {triggers?.map((t: any) => {
              return (
                <p key={t}>
                  <b>{t}:</b> {triggersExplanation[t]}
                </p>
              );
            })}
          </div>
        </section>

        <Row className="final-narrative-button-row">
          <Col>
            {valuePhilosophy && (
              <PDFDownloadLink
                document={
                  <PDFExport
                    userName={userName}
                    valueDrivers={valueDrivers}
                    valuePhilosophy={valuePhilosophy}
                    archetype={archetype}
                    archetypeExplanation={archetypeExplanation}
                    triggers={triggers}
                    triggersExplanation={triggersExplanation}
                  />
                }
                fileName="Your_Fingerprint.pdf"
                className="btn-white no-wrap"
                style={{ textDecoration: "none" }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download now!"
                }
              </PDFDownloadLink>
            )}
          </Col>

          <Col>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                setShowShareLink(!showShareLink);
              }}
              type="submit"
              className="btn-borderless"
            >
              Get Share Link
              <LinkIcon />
            </Button>

            {showShareLink && (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "white",
                }}
                className="standard"
              >
                {`${window.location.origin.toString()}/fingerprint/${licenseCode}`}
              </p>
            )}
          </Col>
        </Row>
      </section>
    </FingerPrintView>
  );
};
