import { serverResponse } from "../services/user.service";

export const errorConstants = {
  ERROR_OCCURRED: "ERROR_OCCURRED",
};

const initialHttpError: serverResponse = { messages: [""], statusCode: 0 };

export const editHttpError = (state = initialHttpError, action: any) => {
  switch (action.type) {
    case errorConstants.ERROR_OCCURRED:
      let newMessages = action?.messages && [...action.messages];

      return { messages: newMessages, statusCode: action.statusCode };
    default:
      return state;
  }
};

const updateHttpError = (messages: Array<string>, statusCode: number) => {
  let action = {
    type: errorConstants.ERROR_OCCURRED,
    messages: messages,
    statusCode: statusCode,
  };
  return (dispatch: any) => {
    dispatch(action);
  };
};

export const errorActions = {
  updateHttpError,
};
